<template>
  <div class="wrapper" @click.self="click_main_content">
    <notifications></notifications>
    <side-bar @click-close="click_close" :autoClose="windowWidth >= 1600 ? false : true">
      <template slot="links">
        <sidebar-item
          :link="{name: $t('Store'), icon: 'ni ni-shop text-primary',}"
          ref="si_store"
          attr="si_store"
          @click-close="click_close"
          class="nav-item-0"
          v-acl:StoreMenu
        >
          <sidebar-item :link="{name: $t('Store'), path: '/store',}" @collapseParent="collapseParent('si_store')" v-acl:Store></sidebar-item>
          <sidebar-item :link="{name: $t('Table'), path: '/table',}" @collapseParent="collapseParent('si_store')" v-acl:Table></sidebar-item>
          <sidebar-item :link="{name: $t('Menu'), path: '/menu',}" @collapseParent="collapseParent('si_store')" v-acl:Menu></sidebar-item>
          <sidebar-item :link="{name: $t('Soldout'), path: '/store_product',}" @collapseParent="collapseParent('si_store')" v-acl:Soldout></sidebar-item>
          <sidebar-item :link="{name: $t('Left Menu'), path: '/left_menu',}" @collapseParent="collapseParent('si_store')" v-acl:LeftMenu></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{name: $t('Setup'), icon: 'ni ni-settings text-primary',}"
          ref="si_setup"
          attr="si_setup"
          @click-close="click_close"
          class="nav-item-1"
          v-acl:SetupMenu
        >
          <sidebar-item :link="{name: $t('Tax'), path: '/tax',}" @collapseParent="collapseParent('si_setup')" v-acl:Tax></sidebar-item>
          <sidebar-item :link="{name: $t('POS Setting'), path: '/possetting',}" @collapseParent="collapseParent('si_setup')" v-acl:POSSetting></sidebar-item>
          <sidebar-item :link="{name: $t('Printer'), path: '/printer',}" @collapseParent="collapseParent('si_setup')" v-acl:Printer></sidebar-item>
          <sidebar-item :link="{name: $t('Payment Setting'), path: '/paysetting',}" @collapseParent="collapseParent('si_setup')" v-acl:PaymentSetting></sidebar-item>
          <sidebar-item :link="{name: $t('Staff'), path: '/staff',}" @collapseParent="collapseParent('si_setup')" v-acl:Staff></sidebar-item>
          <sidebar-item :link="{name: $t('Import / Export'), path: '/import',}" @collapseParent="collapseParent('si_setup')" v-acl:ImportExport></sidebar-item>
          <sidebar-item :link="{name: 'Happy Hour', path: '/happyhour',}" @collapseParent="collapseParent('si_setup')" v-acl:HappyHour></sidebar-item>
          <sidebar-item :link="{name: $t('Delivery'), path: '/delivery',}" @collapseParent="collapseParent('si_setup')" v-acl:Delivery></sidebar-item>
          <sidebar-item :link="{name: 'Quick Notes', path: '/quicknotes',}" @collapseParent="collapseParent('si_setup')" v-acl:QuickNotes></sidebar-item>
          <sidebar-item :link="{name: $t('Cloud Store'), path: '/cloudStore',}" @collapseParent="collapseParent('si_setup')" v-acl:CloudStore></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{name: $t('Product'), icon: 'ni ni-tag text-primary',}"
          ref="si_product"
          attr="si_product"
          @click-close="click_close"
          class="nav-item-2"
          v-acl:ProductMenu
        >
          <sidebar-item :link="{name: $t('Department'), path: '/department',}" @collapseParent="collapseParent('si_product')" v-acl:Department></sidebar-item>
          <sidebar-item :link="{name: $t('Category'), path: '/category',}" @collapseParent="collapseParent('si_product')" v-acl:Category></sidebar-item>
          <sidebar-item :link="{name: $t('Modifier'), path: '/modifier',}" @collapseParent="collapseParent('si_product')" v-acl:Modifier></sidebar-item>
          <sidebar-item v-if='is_retail' :link="{name: $t('Product'), path: '/product',}" @collapseParent="collapseParent('si_product')" v-acl:Product></sidebar-item>
          <sidebar-item v-else :link="{name: $t('Menu Item'), path: '/item_menu',}" @collapseParent="collapseParent('si_product')" v-acl:MenuItem></sidebar-item>
          <sidebar-item :link="{name: 'BOM', path: '/bom',}" @collapseParent="collapseParent('si_product')" v-acl:BOM></sidebar-item>
          <sidebar-item :link="{name: $t('Unit Manage'), path: '/unit_manage',}" @collapseParent="collapseParent('si_product')" v-acl:UnitManage></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{name: 'VIP', icon: 'ni ni-cart text-primary',}"
          ref="si_vip"
          attr="si_vip"
          @click-close="click_close"
          class="nav-item-3"
          v-acl:VIPMenu
        >
          <sidebar-item :link="{name: $t('VIP'), path: '/vip',}" @collapseParent="collapseParent('si_vip')" v-acl:VIP></sidebar-item>
          <sidebar-item :link="{name: $t('VIP Setting'), path: '/vipsetting',}" @collapseParent="collapseParent('si_vip')" v-acl:VIPSetting></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{name: $t('Sale'), icon: 'ni ni-basket text-primary',}"
          ref="si_sale"
          attr="si_sale"
          @click-close="click_close"
          class="nav-item-4"
          v-acl:SaleMenu
        >
          <sidebar-item :link="{name: $t('Customer'), path: '/customer',}" @collapseParent="collapseParent('si_sale')" v-acl:Customer></sidebar-item>
          <sidebar-item :link="{name: $t('Sales Order'),path: '/so',}" @collapseParent="collapseParent('si_sale')" v-acl:SalesOrder></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{name: $t('Purchase'), icon: 'ni ni-bullet-list-67 text-primary',}"
          ref="si_purchase"
          attr="si_purchase"
          @click-close="click_close"
          class="nav-item-5"
          v-acl:PurchaseMenu
        >
          <sidebar-item :link="{name: $t('Vendor'), path: '/Vendor',}" @collapseParent="collapseParent('si_purchase')" v-acl:Vendor></sidebar-item>
          <sidebar-item :link="{name: $t('Purchase Order'), path: '/po',}" @collapseParent="collapseParent('si_purchase')" v-acl:PurchaseOrder></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{name: $t('Inventory'), icon: 'ni ni-calendar-grid-58 text-primary',}"
          ref="si_inventory"
          attr="si_inventory"
          @click-close="click_close"
          class="nav-item-6"
          v-acl:InventoryMenu
        >
          <sidebar-item :link="{name: $t('Inventory'), path: '/inventory',}" @collapseParent="collapseParent('si_inventory')" v-acl:Inventory></sidebar-item>
          <sidebar-item :link="{name: $t('Receiving'), path: '/receiving',}" @collapseParent="collapseParent('si_inventory')" v-acl:Receiving></sidebar-item>
          <sidebar-item :link="{name: $t('Shipping'), path: '/shipping',}" @collapseParent="collapseParent('si_inventory')" v-acl:Shipping></sidebar-item>
          <sidebar-item :link="{name: $t('Inbound'), path: '/inbound',}" @collapseParent="collapseParent('si_inventory')" v-acl:Inbound></sidebar-item>
          <sidebar-item :link="{name: $t('Outbound'), path: '/outbound',}" @collapseParent="collapseParent('si_inventory')" v-acl:Outbound></sidebar-item>
          <sidebar-item :link="{name: $t('Stock Take'), path: '/stock_take',}" @collapseParent="collapseParent('si_inventory')" v-acl:Stocktake></sidebar-item>
          <sidebar-item :link="{name: $t('S/N Management'), path: '/s_n_management',}" @collapseParent="collapseParent('si_inventory')" v-acl:SNManagement></sidebar-item>
          <sidebar-item :link="{name: $t('Transfer'), path: '/transfer',}" @collapseParent="collapseParent('si_inventory')" v-acl:Transfer></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{name: $t('Report'), icon: 'ni ni-ungroup text-primary',}"
          ref="si_report"
          attr="si_report"
          @click-close="click_close"
          class="nav-item-7"
          v-acl:ReportMenu
        >
          <sidebar-item :link="{name: $t('Day End Report'), path: '/pos_day_end_report',}" @collapseParent="collapseParent('si_report')" v-acl:DayEndReport></sidebar-item>
          <sidebar-item :link="{name: $t('Attendance Report'), path: '/in_out_report',}" @collapseParent="collapseParent('si_report')" v-acl:AttendanceReport></sidebar-item>
          <sidebar-item :link="{name: $t('Sales By Category'), path: '/pos_sales_by_category',}" @collapseParent="collapseParent('si_report')" v-acl:SalesByCategory></sidebar-item>
          <sidebar-item :link="{name: $t('POS Sales Report By Category'), path: '/pos_sales_report_by_category',}" @collapseParent="collapseParent('si_report')" v-acl:POSSalesReportByCategory></sidebar-item>
          <sidebar-item :link="{name: $t('Sales By Product'), path: '/pos_sales_by_product',}" @collapseParent="collapseParent('si_report')" v-acl:SalesByProduct></sidebar-item>
          <sidebar-item :link="{name: $t('VIP Sales'), path: '/vip_sales',}" @collapseParent="collapseParent('si_report')" v-acl:VIPSales></sidebar-item>
          <sidebar-item :link="{name: $t('VIP Reload'), path: '/vip_reload',}" @collapseParent="collapseParent('si_report')" v-acl:VIPReload></sidebar-item>
          <sidebar-item :link="{name: $t('POS Transaction'), path: '/pos_transaction_report',}" @collapseParent="collapseParent('si_report')" v-acl:POSTransaction></sidebar-item>
          <sidebar-item :link="{name: $t('Online Order'), path: '/onlineorder',}" @collapseParent="collapseParent('si_report')" v-acl:OnlineOrder></sidebar-item>
          <sidebar-item :link="{name: $t('SO Report'), path: '/so_report',}" @collapseParent="collapseParent('si_report')" v-acl:SOReport></sidebar-item>
          <sidebar-item :link="{name: $t('SO Order Summary'), path: '/so_order_summary',}" @collapseParent="collapseParent('si_report')" v-acl:SOOrderSummary></sidebar-item>
          <sidebar-item :link="{name: $t('Statement By Customer'), path: '/statement_by_customer',}" @collapseParent="collapseParent('si_report')" v-acl:StatementByCustomer></sidebar-item>
          <sidebar-item :link="{name: $t('Purchase Summary'), path: '/purchase_summary',}" @collapseParent="collapseParent('si_si_reportsale')" v-acl:PurchaseSummary></sidebar-item> 
          <sidebar-item :link="{name: $t('Purchase Item'), path: '/purchase_item',}" @collapseParent="collapseParent('si_report')" v-acl:PurchaseItem></sidebar-item> 
          <sidebar-item :link="{name: $t('Inventory Summary'), path: '/inventory_summary',}" @collapseParent="collapseParent('si_report')" v-acl:InventorySummary></sidebar-item> 
          <sidebar-item :link="{name: $t('Modifier Report'), path: '/modifier_report',}" @collapseParent="collapseParent('si_report')" v-acl:ModifierReport></sidebar-item> 
        </sidebar-item>
        <sidebar-item :link="{name: $t('Logout'),path: '/login',icon: 'ni ni-key-25 text-primary',}">
        </sidebar-item>
      </template>

      <template slot="links-after">
        <div class="my-3"></div>
        <b-nav class="navbar-nav mb-md-3" id="right_footer">
          <b-nav-text class="footclass">
            <div class="footdiv">Copyright © 2020-{{ thisyear }}</div>
            <div class="footdiv">POSKING Inc. Ver{{ myversion }}</div>
          </b-nav-text>
        </b-nav>
      </template>
    </side-bar>
    <div class="main-content" :class="$sidebar.showSidebar ? '' : 'content-expanded'" @click="click_main_content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
      <div>
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view :key="$route.fullPath"></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

const axios = require("axios");

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { FadeTransition } from "vue2-transitions";
import { getStore } from "../../util/helper";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition,
  },
  data() {
    return {
      token: "",
      user_id: 0,
      windowWidth: window.innerWidth,
      is_retail: null,
      thisyear: "2023",
      myversion: "3.1.04",
      pkadmin: false,
      lm100: 1,
      lm101: 1,
      lm102: 1,
      lm103: 1,
      lm104: 1,
      lm200: 1,
      lm201: 1,
      lm202: 1,
      lm203: 1,
      lm204: 1,
      lm205: 1,
      lm206: 1,
      lm207: 1,
      lm208: 1,
      lm209: 1,
      lm300: 1,
      lm301: 1,
      lm302: 1,
      lm303: 1,
      lm304: 1,
      lm305: 1,
      lm306: 1,
      lm400: 0,
      lm401: 0,
      lm402: 0,
      lm500: 0,
      lm501: 0,
      lm502: 0,
      lm600: 0,
      lm601: 0,
      lm602: 0,
      lm700: 0,
      lm701: 0,
      lm702: 0,
      lm703: 0,
      lm704: 0,
      lm705: 0,
      lm706: 0,
      lm800: 1,
      lm801: 1,
      lm802: 1,
      lm803: 1,
      lm804: 1,
      lm805: 1,
      lm806: 1,
      lm807: 1,
      lm808: 1,
      lm809: 1,
      lm810: 1,
      lm811: 1,
      lm812: 1,
      lm813: 1,
    };
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    click_main_content() {
      if (this.windowWidth >= 1600) {
        if (!this.$sidebar.showSidebar) {
          if (!this.$refs.si_store.collapsed) this.$refs.si_store.collapseMenu();
          if (!this.$refs.si_setup.collapsed) this.$refs.si_setup.collapseMenu();
          if (!this.$refs.si_product.collapsed) this.$refs.si_product.collapseMenu();
          if (!this.$refs.si_vip.collapsed) this.$refs.si_vip.collapseMenu();
          if (!this.$refs.si_sale.collapsed) this.$refs.si_sale.collapseMenu();
          if (!this.$refs.si_purchase.collapsed) this.$refs.si_purchase.collapseMenu();
          if (!this.$refs.si_inventory.collapsed) this.$refs.si_inventory.collapseMenu();
          if (!this.$refs.si_report.collapsed) this.$refs.si_report.collapseMenu();
        }
      } else {
        this.$sidebar.displaySidebar(false);
      }
    },
    click_close(ref) {
      if (this.windowWidth >= 1600) {
        if (ref != 'si_store' && !this.$refs.si_store.collapsed) this.$refs.si_store.collapseMenu();
        if (ref != 'si_setup' && !this.$refs.si_setup.collapsed) this.$refs.si_setup.collapseMenu();
        if (ref != 'si_product' && !this.$refs.si_product.collapsed) this.$refs.si_product.collapseMenu();
        if (ref != 'si_vip' && !this.$refs.si_vip.collapsed) this.$refs.si_vip.collapseMenu();
        if (ref != 'si_sale' && !this.$refs.si_sale.collapsed) this.$refs.si_sale.collapseMenu();
        if (ref != 'si_purchase' && !this.$refs.si_purchase.collapsed) this.$refs.si_purchase.collapseMenu();
        if (ref != 'si_inventory' && !this.$refs.si_inventory.collapsed) this.$refs.si_inventory.collapseMenu();
        if (ref != 'si_report' && !this.$refs.si_report.collapsed) this.$refs.si_report.collapseMenu();
      }
    },
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/left_menu",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              if (rt.data.left_menu && rt.data.left_menu.Menu) {
                var left_menu = JSON.parse(rt.data.left_menu.Menu);
                for (var i=0; i<left_menu.length; i++) {
                  that['lm'+left_menu[i].id] = left_menu[i].Status;
                }
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log("init_data",error);
          that.$router.push("/login");
        });
    },
    collapseParent(parentRef) {
      if (this.windowWidth >= 1600) {
        if (!this.$sidebar.showSidebar) {
          if (!this.$refs[parentRef].collapsed) this.$refs[parentRef].collapseMenu();
        }
      }
    },
  },
  mounted() {
    this.token = getStore("token");
    this.user_id = getStore("user_id");
    let name = getStore("username");
    var today = new Date();
    this.thisyear = today.getFullYear();

    if (name && name != undefined && name == "PKAdmin") {
      this.pkadmin = true;
    }

    // left menu open to everyone temporarily
    this.pkadmin = true;

    // this.initScrollbar();
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  beforeMount() {
    this.is_retail = getStore("is_retail");
    window.removeEventListener('resize', this.onResize);
  },
};
</script>
<style lang="scss">
#right_footer .footclass {
  font-size: 0.8rem;
  width: 80%;
  .footdiv {
    position: relative;
    left: 4.5rem;
  }
}
.wrapper {
  position: relative;
  min-height: 100%;
}
</style>
